import React, { memo } from 'react';
import { graphql } from 'gatsby';
import { MainPage } from '../templates/MainPage';
import SeoText from 'components/SeoText';
import { Helmet } from "react-helmet"

const SemkaKorporativnyhVideo = () => {
  return (
    <>
      <Helmet>
        <title>Съемка корпоративных видео в Москве цена от Elandic</title>
        <meta name="description" content="Съемка корпоративных видео цена в Москве услуги под ключ на сайте студии Elandic. Заказать услуги записи корпоративных роликов стоимость от видеопродакшена." />
        <meta name="keywords" content="съемка корпоративного видео, роликов, запись" />
      </Helmet>
      <MainPage>
        <SeoText title='Съемка корпоративных видео'>
          <p>В современном бизнесе корпоративное видео стало неотъемлемой частью маркетинговых стратегий. Оно помогает компаниям эффективно донести свои идеи, миссию и ценности до сотрудников и клиентов. Если вы хотите заказать съемку корпоративного видео в Москве, то компания Elandic готова предложить вам свои профессиональные услуги по доступной цене.</p>

          <p>Компания Elandic специализируется на создании качественных корпоративных видео и роликов, которые помогают бизнесам достигать своих целей. Мы понимаем, что каждая компания уникальна, поэтому подходим к каждому проекту индивидуально, учитывая все ваши пожелания и требования.</p>

          <h2>Преимущества работы с Elandic:</h2>

          <ol>
            <li>Профессиональная команда: В нашем штате работают опытные операторы, режиссеры, сценаристы и монтажеры, которые создадут для вас видео высокого качества.</li>
            <li>Современное оборудование: Мы используем новейшую технику для съемки и обработки видео, что гарантирует отличное качество изображения и звука.</li>
            <li>Индивидуальный подход: Мы тщательно изучаем ваши потребности и создаем видео, которое полностью соответствует вашим ожиданиям.</li>
            <li>Доступные цены: Мы предлагаем конкурентоспособные цены на съемку корпоративных видео в Москве. Стоимость наших услуг зависит от сложности проекта, его продолжительности и других факторов, но мы всегда готовы предложить оптимальное решение для вашего бюджета.</li>
          </ol>

          <p>Корпоративное видео — это мощный инструмент для продвижения вашего бизнеса и укрепления корпоративной культуры. Компания Elandic готова помочь вам создать качественный видеоконтент, который будет соответствовать вашим целям и задачам.</p>

          <p>Заказать съемку корпоративного видео в Москве у нас — это значит получить профессиональный подход, высокое качество и доступную цену. Свяжитесь с нами уже сегодня, и мы поможем вам воплотить вашу идею в жизнь!</p>

        </SeoText>
      </MainPage>
    </>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        ns: { in: ["mainPage", "common", "layout"] }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default memo(SemkaKorporativnyhVideo);
